.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}


.btn-toolbar {
  display: contents !important;
}
.project-add{
  margin-top: 0px !important;
  width: 135px; 
  // background-color: #1d55a6;

}
.project-toggle{
  margin-right: 12px;
}

.active-btn{
  color: #fff;
  background-color: #135dff;
  border-color: #0654ff;
}

label.btn-primary {
  color: #000;
  background-color: #ebeff4;
  border-color: #ecf0f5;
}

.logo-dashboard{
  object-fit: contain;
  height: 50px;
  position: fixed;
  font-size: 35px;
  color: white;
  font-weight: 1000;
  font-family : revert;
  left: 10px;
  margin-top: 11px;

}

.border-feild{
  padding-bottom: 10px;
  margin-top: 11px;
}

.border-in{
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 5px !important;
}

@media screen and (max-width:767px) {
  .project-add{
    display: flex ;
    // margin-top: 5px !important;
  }
  .project-toggle{
    margin-right: 0px !important;
   }
   .logo-dashboard{
    object-fit: contain;
    font-family : monospace;
    width: 200px;
    font-weight: 1000;
    position: relative;

  }
}
@media screen and (max-width:991px) {
  .logo-dashboard{
    
    position: absolute;
    
  }
}

table.dataTable thead .sorting_desc {
   background-image: none !important;
}

table.dataTable thead .sorting{
  background-image: none !important;
}